import { createApp } from "vue";
import App from "./App.vue";
import { animation } from "@/directives/animation";

import "./scss/main.scss";

const vm = createApp(App);

vm.directive("animation", animation);
vm.mount("#app");
