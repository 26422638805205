<template>
  <footer class="footer">
    <nav class="footer-menu">
      <ul class="footer-nav">
        <li
          v-for="(item, i) in menu"
          :key="`footer_menu_item_${i}`"
          v-animation="{
            name: 'fadeUp',
            duration: (i + 1) * 0.3,
          }"
        >
          <a
            :href="item.url"
            :target="item.target ?? null"
            v-html="item.name"
          />
        </li>
      </ul>
    </nav>
    <a
      class="footer__logo"
      href="https://vk.com/vkdesignteam"
      target="_blank"
      v-animation="{
        name: 'fadeUp',
        duration: 1,
      }"
    >
      <img src="@/assets/img/logo-footer.svg" alt="Design team" />
    </a>
  </footer>
</template>

<script>
export default {
  setup() {
    const menu = [
      {
        name: "Спикеры",
        url: "#speakers",
      },
      {
        name: "Расписание",
        url: "https://www.figma.com/proto/sgmfOcodWtpgEMHBy7kOh6/VK-Design-Conf-ex-Dribbble-Meetup-22%E2%80%9424?page-id=4408%3A6&node-id=4408-7&viewport=519%2C561%2C0.43&t=91driqGD0UQu4RAt-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=4408%3A7",
        target: "_blank",
      },
      {
        name: "Партнеры",
        url: "#partners",
      },
      {
        name: "Вакансии",
        url: "https://team.vk.company/vacancy/?specialty=273",
        target: "_blank",
      },
      {
        name: "VK JT 2024",
        url: "https://vkjt.ru/",
        target: "_blank",
      },
    ];

    return { menu };
  },
};
</script>

<style lang="scss">
$b: ".footer";

#{$b} {
  display: block;
  padding: vw(12px 33px 32px);
  background-color: $color-black;
  color: $color-white;
  font-size: vw(16px);
  line-height: vw(28px);

  &__logo {
    display: flex;
    width: vw(256px);
    height: vw(45px);

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-nav {
    list-style: none;
    padding: 0;

    & > li {
      &:not(:last-child) {
        margin-bottom: vw(11px);
      }

      & > a {
        display: inline-block;
        text-decoration: none;
        font-weight: 500;
        color: $color-white;
      }
    }
  }
}
</style>
