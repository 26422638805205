<template>
  <div class="info-slider">
    <swiper :modules="modules" v-bind="swiperOptions">
      <swiper-slide v-for="(slide, i) in slides" :key="`info_slide_${i}`">
        <div :class="`info-slider-slide ${slide.classes ? slide.classes : ''}`">
          <div class="info-slider-slide__title" v-html="slide.title" />
          <div
            v-if="slide.icon"
            class="info-slider-slide__icon"
            v-html="slide.icon"
          />
          <div class="info-slider-slide__text" v-html="slide.text" />
          <div
            v-for="(image, k) in slide.images"
            :key="`info-slider-slide_${i + 1}_image_${k + 1}`"
            class="info-slider-slide__img"
          >
            <img :src="require(`@/assets/${image.src}`)" />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const swiperOptions = {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: 1,
      spaceBetween: 24,
      pagination: { clickable: true },
    };
    const slides = [
      {
        title: "при поддержке",
        text: "конференция <br>о технологиях",
        classes: "info-slider-slide--main",
        images: [{ src: "img/info-slider-bg.png" }],
        icon: `<svg width="160" height="46" viewBox="0 0 160 46" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'" v-bind:class="'svg-inline'"><path d="M157.774 18.428h-9.132l-3.655 14.294c-.566 2.775.113 3.965 4.36 3.965h4.828l.027 8.948h-9.066c-9.059 0-11.55-4.984-9.739-13.479l3.542-13.728h-8.615l.011-8.665h10.415l1.648-6.352h9.424l-1.526 6.352H160l-2.226 8.665zM110.739 36.717c1.196 0 2.013-.379 2.594-1.057.609-.745.978-1.842.978-3.418V9.769h9.901v22.473c0 4.077-1.415 7.412-3.79 9.865l-.004.003-.003.003c-2.384 2.422-5.658 3.573-9.676 3.573h-6.118l-.004-8.97h6.122z" fill="#fff"/><path d="M104.6.056h9.746v9.746H104.6V.056z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M64.26 15.51L80.332.055h13.941l-22.73 21.928 24.649 23.702H81.326L64.26 29.181v16.505H53.29V.056h10.97v15.453zM30.726 45.686H17.993L0 .056h12.4l12.005 33.356L36.842.056h12.444l-18.56 45.63z" fill="#fff"/></svg>`,
      },
      {
        title: "Лекции <br>и общение",
        text: "После утомительного перерыва мы&nbsp;наконец проведём нашу дизайн-конференцию в&nbsp;обновлённом формате. Совместно с&nbsp;VK&nbsp;JT&nbsp;соберём в&nbsp;<a href='https://team.vk.company/skylight/' target='_blank'>московском офисе&nbsp;VK</a> дизайнеров, креаторов и&nbsp;всех, кому интересен цифровой дизайн.<br> Москва, Ленинградский проспект, д.&nbsp;39, стр.&nbsp;79, БЦ&nbsp;Skylight",
      },
      {
        title: "Мы <br>в&nbsp;Telegram",
        text: "За&nbsp;новостями конференции можно по-прежнему следить в&nbsp;<a href='https://t.me/dribbblemeetup' target='_blank'>Telegram</a>. В&nbsp;нашем чате можно задавать вопросы спикерам и&nbsp;общаться с&nbsp;другими зрителями и участниками трансляции.",
      },
      {
        title: "Мы <br>ВКонтакте",
        text: "Теперь следить за&nbsp;конференцией можно в&nbsp;нашем сообществе ВКонтакте. Там появится ссылка на&nbsp;трансляцию и&nbsp;другие полезные новости &mdash;<a href='https://vk.com/vkdesignteam' target='_blank'>подписывайтесь</a> на&nbsp;обновления.",
      },
    ];

    return {
      slides,
      swiperOptions,
      modules: [Pagination, Autoplay],
    };
  },
};
</script>

<style lang="scss">
$b: ".info-slider";

#{$b} {
  display: block;

  .swiper-slide {
    height: auto;
  }

  &-slide {
    position: relative;
    height: 100%;
    background-color: $color-dark;
    padding: vw(23px 28px 50px 28px);
    border-radius: vw(20px);
    overflow: hidden;

    &--main {
      padding-bottom: vw(106px);
    }

    &__title {
      position: relative;
      display: block;
      font-family: $font-alt;
      font-weight: 400;
      font-size: vw(28px);
      line-height: vw(32px);
      color: inherit;
      margin: vw(0 0 21px);
      z-index: 2;

      #{$b}-slide--main & {
        font-size: vw(20px);
        line-height: vw(24px);
      }
    }

    &__text {
      position: relative;
      width: vw(256px);
      display: block;
      z-index: 2;

      #{$b}-slide--main & {
        font-family: $font-alt;
        font-size: vw(20px);
        line-height: vw(24px);
      }
    }

    &__icon {
      position: relative;
      margin-bottom: vw(14px);
      z-index: 2;
    }

    &__img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      img {
        width: 100%;
      }
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: vw(24px);
    left: vw(28px);
    right: 0;
    z-index: 10;
  }
}
</style>
