<template>
  <div class="app">
    <header-template />
    <hero-template />
    <schedule-line />
    <registration-template />
    <speakers-template />
    <partners-template />
    <footer-template />
  </div>
</template>

<script>
import HeaderTemplate from "@/components/HeaderTemplate";
import FooterTemplate from "@/components/FooterTemplate";
import HeroTemplate from "@/components/HeroTemplate";
import ScheduleLine from "@/components/ScheduleLine";
import RegistrationTemplate from "@/components/RegistrationTemplate";
import SpeakersTemplate from "@/components/SpeakersTemplate";
import PartnersTemplate from "@/components/PartnersTemplate";

export default {
  components: {
    HeaderTemplate,
    HeroTemplate,
    ScheduleLine,
    FooterTemplate,
    RegistrationTemplate,
    SpeakersTemplate,
    PartnersTemplate,
  },
};
</script>

<style lang="scss"></style>
