<template>
  <div class="burger-menu" v-body-scroll-lock="isShow">
    <div class="burger-menu__toggle" @click.prevent="openMenu">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" fill="none" viewBox="0 0 20 10" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'" v-bind:class="'svg-inline'"><path fill="#fff" d="M0 0h20v2H0zm0 8h20v2H0z"/></svg>
    </div>
    <transition name="fade">
      <div v-show="isShow" class="burger-menu__popup">
        <div class="burger-menu__close" @click.prevent="closeMenu">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'" v-bind:class="'svg-inline'"><path fill="#fff" d="M1.636.222l14.142 14.142-1.414 1.414L.222 1.636z"/><path fill="#fff" d="M.222 14.364L14.364.222l1.414 1.414L1.636 15.778z"/></svg>
        </div>
        <nav class="burger-menu__nav">
          <ul class="burger-menu__list">
            <li
              v-for="(item, i) in items"
              :key="`burger_menu_item_${i}`"
              v-animation="{
                name: 'fadeUp',
                duration: (i + 1) * 0.2,
                toggle: true,
              }"
            >
              <a
                :href="item.url"
                :target="item.target ?? null"
                @click="closeMenu"
                v-html="item.name"
              />
            </li>
            <li class="burger-menu__registration-btn">
              <a
                href="https://vk.company/ru/press/events/1267/"
                target="_blank"
                class="button button_border"
              >
                <span>Регистрация</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </transition>
  </div>
</template>

<script>
import { bodyScrollLock } from "@/directives/bodyScrollLock";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  directives: {
    "body-scroll-lock": bodyScrollLock,
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    openMenu() {
      this.isShow = true;
    },
    closeMenu() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss">
$b: ".burger-menu";

#{$b} {
  display: block;

  &__toggle,
  &__close {
    @include clickable;
    width: vw(20px);
    height: vw(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: vw(4px);

    .svg-inline {
      width: 100%;
      height: 100%;
    }
  }

  &__close {
    position: absolute;
    top: vw(26px);
    right: vw(20px);
  }

  &__popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: $color-black;
    padding: vw(100px 30px 100px 32px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      &:not(:last-child) {
        margin-bottom: vw(28px);
      }

      & > a {
        display: inline-block;
        text-decoration: none;
        color: inherit;
        font-size: vw(20px);
        line-height: vw(34px);
        font-weight: 400;
        color: $color-white;
        letter-spacing: vw(0.4px);
      }
    }
  }

  &__registration-btn {
    .button {
      display: flex;
      font-size: vw(15px);
      height: vw(55px);
      border-radius: vw(19px);
      padding: 0 19px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s $animFunction;
    opacity: 1;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
