<template>
  <div class="registration">
    <div class="container">
      <div
        class="registration__panel"
        v-animation="{
          name: 'fadeUp',
          duration: 0.8,
        }"
      >
        <div class="registration__title" v-html="title" />
        <div class="registration__text" v-html="text" />
        <div class="registration__buttons">
          <a
            href="https://vk.company/ru/press/events/1267/"
            target="_blank"
            class="button button_border"
          >
            <span v-html="buttonText" />
          </a>
        </div>
      </div>
      <info-slider
        v-animation="{
          name: 'fadeUp',
          duration: 0.8,
        }"
      />
    </div>
  </div>
</template>

<script>
import InfoSlider from "@/components/InfoSlider";

export default {
  components: {
    InfoSlider,
  },
  setup() {
    const title = "20 сентября московский офис VK";
    const text =
      "Все новости и&nbsp;обновления конференции теперь <a href='https://vk.com/vkdesignteam' target='_blank'>ВКонтакте</a> и&nbsp;в&nbsp;<a href='https://t.me/dribbblemeetup' target='_blank'>Telegram</a>. Подписывайтесь, чтобы ничего не&nbsp;пропустить";
    const buttonText = "Регистрация";

    return { title, text, buttonText };
  },
};
</script>

<style lang="scss">
$b: ".registration";

#{$b} {
  display: block;
  font-weight: 300;
  font-size: vw(14px);
  line-height: vw(18px);
  margin-bottom: vw(28px);

  &__panel {
    height: 100%;
    background-color: $color-black;
    padding: vw(28px);
    border-radius: vw(20px);

    &:not(:last-child) {
      margin-bottom: vw(4px);
    }
  }

  &__title {
    max-width: vw(400px);
    display: block;
    font-family: $font-alt;
    font-weight: 400;
    font-size: vw(28px);
    line-height: vw(32px);
    color: inherit;
    margin: vw(0 0 14px);
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: vw(24px);
    }
  }

  &__buttons {
    .button {
      width: 100%;
    }
  }
}
</style>
