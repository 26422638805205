<template>
  <div id="speakers" class="speakers">
    <div class="container">
      <ul class="speakers-list">
        <li
          v-for="(speaker, i) in speakers"
          :key="`speaker_${i}`"
          :class="`_item-${i + 1}`"
          v-animation="{
            name: 'fadeUp',
            duration: 0.6,
          }"
        >
          <component
            :is="speaker.url ? 'a' : 'div'"
            :href="speaker?.url"
            :target="speaker?.target"
            class="speaker"
            :class="[`speaker_item-${i + 1}`]"
          >
            <div class="speaker__num">
              {{ getNum(i) }}
            </div>
            <div
              v-for="(name, k) in speaker.names"
              :key="`speaker_name_${k}`"
              class="speaker__name"
              v-html="name"
            />
            <div v-if="speaker.workPlace" class="speaker__arrow"></div>
            <div
              v-if="speaker?.workPlace"
              class="speaker__work-place"
              v-html="speaker.workPlace"
            />
          </component>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const speakers = [
      {
        names: ["Команда Mail"],
        url: "#",
        target: "_blank",
      },
      {
        names: ["Кира Калимулина"],
        workPlace: "VK",
        url: "#",
        target: "_blank",
      },
      {
        names: ["Анна Бичевская"],
        workPlace: "Humanity",
        url: "#",
        target: "_blank",
      },
      {
        names: ["Наталья Климчук"],
        url: "#",
        target: "_blank",
      },
      {
        names: ["Валерий Сироткин"],
        workPlace: "VK",
        url: "#",
        target: "_blank",
      },
      {
        names: ["Митя Осадчук"],
        workPlace: "ex - ИВИ",
        url: "#",
        target: "_blank",
      },
      {
        names: ["Мария Титова"],
        workPlace: "VK",
        url: "#",
        target: "_blank",
      },
      {
        names: ["Екатерина Черкес-заде"],
        url: "#",
        target: "_blank",
      },
      {
        names: ["Константин Лобанов"],
        workPlace: "Wemakefab",
        url: "#",
        target: "_blank",
      },
      {
        names: ["Анна Громова"],
        workPlace: "VK",
        url: "#",
        target: "_blank",
      },
      {
        names: ["Захар День"],
        workPlace: "ВШЭ",
        url: "#",
        target: "_blank",
      },
      {
        names: ["и другие ..."],
        url: "#",
        target: "_blank",
      },
    ];

    const getNum = (index) => {
      const num = index + 1;
      return num > 9 ? num : `0${num}`;
    };

    return { speakers, getNum };
  },
};
</script>

<style lang="scss">
$b: ".speakers";
$c: ".speaker";

#{$b} {
  display: block;

  &-list-wrapper {
    display: flow-root;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      &:not(:last-child) {
        margin-bottom: vw(4px);
      }
    }
  }
}

#{$c} {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  background-color: $color-dark;
  color: $color-white;
  border-radius: vw(20px);
  padding: vw(23px 16px 26px 30px);
  font-family: $font-alt;
  font-weight: 400;
  font-size: vw(18px);
  line-height: vw(24px);
  transition: background-color 0.3s $global-hover-effect;

  &:not(#{$c}_more):hover {
    background-color: $color-brand !important;
    color: $color-white !important;
  }

  &_item-1,
  &_item-4,
  &_item-8,
  &_item-10 {
    background-color: $color-black;
  }

  &__num {
    position: absolute;
    top: vw(17px);
    left: vw(12px);
    display: inline-block;
    font-family: $font-base;
    font-weight: 300;
    font-size: vw(12px);
    line-height: vw(16px);
    color: rgba($color-white, 0.6);
  }

  &__name {
    margin-right: vw(8px);

    #{$c}_item-3 &,
    #{$c}_item-11 & {
      margin-right: vw(70px);
    }

    #{$c}_item-10 & {
      margin-right: vw(6px);
    }

    #{$c}_item-2 &,
    #{$c}_item-6 &,
    #{$c}_item-7 &,
    #{$c}_item-9 & {
      width: 100%;
    }
  }

  &__work-place {
    white-space: nowrap;
  }

  &__arrow {
    flex: 1 1 auto;
    position: relative;
    min-height: vw(24px);
    margin-right: vw(8px);

    &:before {
      content: "";
      position: absolute;
      top: vw(10.7px);
      right: vw(-1px);
      width: vw(4.6px);
      height: vw(7.5px);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='4.2' height='7.4' viewBox='0 0 4.2 7.4'%3E%3Cpath d='M4 4c.2-.2.2-.5 0-.7L.9.1C.7 0 .3 0 .1.1 0 .3 0 .7.1.9L3 3.7.1 6.5c-.1.2-.1.5 0 .7.2.2.5.2.7 0L4 4z' fill='%23ffffff'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center right;
      background-size: contain;
    }

    &:after {
      content: "";
      display: block;
      margin-top: vw(14px);
      min-width: vw(50px);
      height: vw(1px);
      background-color: $color-white;
    }
  }
}
</style>
