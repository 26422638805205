<template>
  <header class="header">
    <div
      class="header__inner"
      v-animation="{
        name: 'fade',
        duration: 1.2,
      }"
    >
      <div class="header__left">
        <a
          class="header__logo"
          href="https://vk.com/vkdesignteam"
          target="_blank"
        >
          <img src="@/assets/img/logo.svg" alt="Design team" />
        </a>
      </div>
      <div class="header__right">
        <burger-menu :items="menu" />
      </div>
    </div>
  </header>
</template>

<script>
import BurgerMenu from "@/components/BurgerMenu";

export default {
  components: {
    BurgerMenu,
  },
  setup() {
    const menu = [
      {
        name: "Спикеры",
        url: "#speakers",
      },
      {
        name: "Расписание",
        url: "https://www.figma.com/proto/sgmfOcodWtpgEMHBy7kOh6/VK-Design-Conf-ex-Dribbble-Meetup-22%E2%80%9424?page-id=4408%3A6&node-id=4408-7&viewport=519%2C561%2C0.43&t=91driqGD0UQu4RAt-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=4408%3A7",
        target: "_blank",
      },
      {
        name: "Партнёры",
        url: "#partners",
      },
      {
        name: "VK JT 2024",
        url: "https://vkjt.ru/",
        target: "_blank",
      },
    ];

    return { menu };
  },
};
</script>

<style lang="scss">
$b: ".header";

#{$b} {
  display: block;
  padding: vw(20px);
  background-color: $color-black;
  color: $color-white;
  margin-bottom: vw(4px);

  &__inner {
    display: flex;
  }

  &__left {
    flex: 1 1 auto;
  }

  &__right {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > * {
      &:not(:last-child) {
        margin-right: vw(40px);
      }
    }
  }

  &__logo {
    display: flex;
    width: vw(229px);
    height: vw(32px);

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
</style>
