export const bodyScrollLock = {
  updated(el, binding) {
    el.isShow = binding.value;
    el.page = document.querySelector("html");

    if (el.isShow) {
      el.page.classList.add("body-scroll-lock");
    } else {
      el.page.classList.remove("body-scroll-lock");
    }
  },
  unmounted(el) {
    if (el.page) {
      el.page.classList.remove("body-scroll-lock");
    }
  },
};
