<template>
  <div class="runline-slider">
    <swiper :modules="modules" v-bind="swiperOptions">
      <slot />
    </swiper>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { Autoplay } from "swiper";
import { Swiper } from "swiper/vue";
import "swiper/css";

const props = defineProps({
  spaceBetween: { type: Number, default: 13.2 },
  speed: { type: Number, default: 10000 },
});

const modules = [Autoplay];

const swiperOptions = {
  loop: true,
  allowTouchMove: false,
  freeMode: {
    enabled: true,
  },
  speed: props.speed,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  slidesPerView: "auto",
  spaceBetween: props.spaceBetween,
};
</script>

<style lang="scss">
$b: ".runline-slider";

#{$b} {
  .swiper-wrapper {
    transition-timing-function: linear !important;
  }
}
</style>
