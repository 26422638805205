<template>
  <div
    class="schedule-line"
    v-animation="{
      name: 'fade',
      duration: 1.5,
    }"
  >
    <runline-slider>
      <swiper-slide
        v-for="(item, i) in list"
        :key="`schedule_item_${i}`"
        class="arrow-el"
      >
        <div class="schedule-line__item arrow-el__arrow">
          <div class="schedule-line__text">
            <span v-html="item.title" />
          </div>
          <div class="schedule-line__text">
            <span v-html="item.place" />
          </div>
          <div class="schedule-line__text">
            <span class="schedule-line__date">
              <span v-html="item.date" />
            </span>
          </div>
          <div class="schedule-line__text">
            <span v-if="item.time" class="schedule-line__time">
              <span v-html="item.time" />
            </span>
          </div>
        </div>
      </swiper-slide>
    </runline-slider>
  </div>
</template>

<script>
import { SwiperSlide } from "swiper/vue";
import RunlineSlider from "@/components/RunlineSlider";

export default {
  components: {
    SwiperSlide,
    RunlineSlider,
  },
  setup() {
    const clonedSize = 3;
    const list = [
      {
        title: "vk dsgn conf × vk jt ",
        place: "offline",
        date: "moscow",
        time: "2024",
      },
    ];

    for (let i = 1; i < clonedSize; i++) {
      list.push(list[0]);
    }

    return { list };
  },
};
</script>

<style lang="scss">
$b: ".schedule-line";

#{$b} {
  overflow: hidden;
  background-color: $color-black;
  color: $color-white;
  margin-bottom: vw(4px);

  .swiper-slide {
    width: auto;
  }

  &__item {
    width: 100%;
    white-space: nowrap;

    & > * {
      &:not(:last-child) {
        &::after {
          content: "";
          width: vw(4.4px);
          height: vw(4.4px);
          background-color: currentColor;
          border-radius: vw(12px);
          margin: vw(0 13.2px);
        }
      }
    }
  }

  &__text {
    display: inline-flex;
    align-items: center;
    font-family: $font-alt;
    font-weight: 400;
    font-size: vw(20px);
    line-height: vw(52px);

    & > span {
      position: relative;
      top: vw(-2px);
    }
  }

  &__time {
    font-size: vw(16px);
  }

  .arrow-el {
    &__arrow {
      &:after {
        min-width: vw(38.5px);
        margin-left: vw(10px);
      }
    }
  }
}
</style>
