<template>
  <div id="partners" class="partners">
    <div class="container">
      <ul class="partners-list">
        <li
          v-for="(partner, i) in partners"
          :key="`partner_${i}`"
          v-animation="getAnimation(i)"
        >
          <div class="partner">
            <div class="partner__logo">
              <img
                :src="require(`@/assets/${partner.logo.src}`)"
                :style="`width: ${vw(partner.logo.width)}; height: ${
                  partner.logo.height
                };`"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const partners = [
      {
        logo: {
          src: "img/partner-1.svg",
          width: 112,
          height: 48,
        },
      },
      {
        logo: {
          src: "img/partner-3.svg",
          width: 112,
          height: 48,
        },
      },
      {
        logo: {
          src: "img/partner-4.svg",
          width: 112,
          height: 48,
        },
      },
      {
        logo: {
          src: "img/partner-5.svg",
          width: 112,
          height: 48,
        },
      },
      {
        logo: {
          src: "img/partner-6.svg",
          width: 112,
          height: 48,
        },
      },
    ];

    const vw = (value) => {
      const baseVW = 320;

      return (value * 100) / baseVW + "vw";
    };

    const getAnimation = (i) => {
      const index = i + 1;
      const baseDuration = 0.4;
      const stepDuration = 0.1;

      return {
        name: "fadeUp",
        duration:
          index === 1 || index === 2
            ? baseDuration
            : index % 2 === 0
            ? (index - 1) * stepDuration + baseDuration
            : index * stepDuration + baseDuration,
      };
    };

    return { partners, vw, getAnimation };
  },
};
</script>

<style lang="scss">
$b: ".partners";
$c: ".partner";

#{$b} {
  display: block;
  overflow: hidden;
  margin: vw(4px 0);
  padding: vw(28px 0);

  &-list {
    list-style: none;
    padding: 0;
    margin: vw(0 0 -28px -41px);
    display: flex;
    flex-wrap: wrap;

    & > * {
      width: 50%;
      padding: vw(0 0 28px 41px);

      &:nth-child(2n + 1) {
        padding-left: vw(64px);

        #{$c}__logo {
          justify-content: center;
        }
      }
    }
  }
}

#{$c} {
  height: 100%;
  display: block;
  text-decoration: none;

  &__logo {
    height: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
